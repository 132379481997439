import React from 'react';
import { useIntl } from 'react-intl';
import tw from 'twin.macro';
import { GrantArticleHeading, SectionWrapper } from './style';
import { DetailsItemType } from './type';

interface GrantArticleDetailsProps {
  detailsList: DetailsItemType[];
  heading?: string;
}

const DetailItemsListWrapper = tw.div`
    grid grid-cols-1 lg:grid-cols-3 items-start gap-6 mt-2 lg:mt-6
`;

const ItemTitle = tw.h3`
    text-[20px] lg:text-[24px] md:font-[500] mb-0 lg:mb-2 text-base_text
`;
const ItemDescription = tw.p`
    text-[14px] lg:text-[16px] text-dark_black mb-0
`;

const GrantArticleDetails = ({
  detailsList,
  heading,
}: GrantArticleDetailsProps) => {
  const { formatMessage } = useIntl();
  return (
    <SectionWrapper>
      <GrantArticleHeading>
        {heading ?? formatMessage({ id: 'grantArticle.details.heading' })}
      </GrantArticleHeading>

      <DetailItemsListWrapper>
        {detailsList?.map(({ id, title, description }) => (
          <div key={id}>
            <ItemTitle>{title}</ItemTitle>
            <ItemDescription>{description}</ItemDescription>
          </div>
        ))}
      </DetailItemsListWrapper>
    </SectionWrapper>
  );
};

export default GrantArticleDetails;
